@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.panel-header {
  display: flex;
  align-items: center;
  padding: 0.25rem .75rem;
  color: $primary;
  border-bottom: 1px solid $gray-300;
  min-height: 50px;
}
