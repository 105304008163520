@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .left-side-menu {
    position: relative;
    padding-bottom: 0;
  }

  &[data-sidebar-size=condensed] {
    .left-side-menu {
      position: relative;
    }
  }

  &:not([data-sidebar-size=condensed]) {
    .left-side-menu {
      @include media-breakpoint-down(sm) {
        position: fixed;
      }
    }
  }

  #root {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    #wrapper {
      display: flex;
      flex-direction: row;
      overflow: hidden;

      .left-side-menu {
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;

        .left-menu-list {
          overflow: hidden;
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }

      .content-page {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        position: relative;
        margin: 0;
        padding: 0;

        .content {
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: hidden;

          .navbar-custom {
            position: relative;
            left: 0;
          }

          main {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            flex: 1;
          }

          footer {
            position: relative;
            left: 0;
          }
        }
      }
    }
  }
}
