@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

$primary: #07689F;
$secondary: #C9D6DF;
$success: #11D3BC;
$danger: #F67280;
$info: #A2D5F2;
$warning: #FF7E67;
$light: #FAFAFA;
$dark: #4e4e4e;

$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$font-family-secondary: 'Source Sans Pro', sans-serif;
