@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.swal2-icon.swal2-success {
  border-color: $success;
  color: $success;
}

.swal2-icon.swal2-warning {
  border-color: $warning;
  color: $warning;
}

.swal2-icon.swal2-info {
  border-color: $info;
  color: $info;
}

.swal2-icon.swal2-error {
  border-color: $danger;
  color: $danger;
}
