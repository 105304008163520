@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

label {
  margin: 0;
}

.form-control:disabled, .form-control[readonly] {
  background-color: $gray-200;
}
