@import "src/assets/scss/variables";

@mixin space-child($margin-selector, $margin) {
  > *:not(:last-child) {
    #{$margin-selector}: $margin;
  }
}

.child-spacing-x-1 { @include space-child(margin-right, map-get($spacers, 1)) }
.child-spacing-x-2 { @include space-child(margin-right, map-get($spacers, 2)) }
.child-spacing-x-3 { @include space-child(margin-right, map-get($spacers, 3)) }
.child-spacing-x-4 { @include space-child(margin-right, map-get($spacers, 4)) }
.child-spacing-x-5 { @include space-child(margin-right, map-get($spacers, 5)) }

.child-spacing-y-1 { @include space-child(margin-bottom, map-get($spacers, 1)) }
.child-spacing-y-2 { @include space-child(margin-bottom, map-get($spacers, 2)) }
.child-spacing-y-3 { @include space-child(margin-bottom, map-get($spacers, 3)) }
.child-spacing-y-4 { @include space-child(margin-bottom, map-get($spacers, 4)) }
.child-spacing-y-5 { @include space-child(margin-bottom, map-get($spacers, 5)) }
