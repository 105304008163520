.scroll-pane {
  height: 100%;
  position: relative;

  &.vertical-only {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &:not(.vertical-only) {
    overflow: auto;
  }
}
