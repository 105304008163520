.navbar-custom {
  .nav-link {
   &.active {
     color: white;
   }
  }
}

.nav-pills .nav-link {
  cursor: pointer;
}

.flex-tabs {
  .tab-pane.active {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}
