.base-table {
  overflow: scroll !important;

  .rdg-filter-row {
    font-weight: normal;
  }

  &.hide-cell-border {
    .rdg-cell[aria-selected=true] {
      box-shadow: none;
    }
  }

  &.fill-height {
    height: 100%;
  }

  .rdg-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}
