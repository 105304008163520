$scrollbar-color: #acacac;
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-color transparent;
  transition: scrollbar-color .3s;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    transition: background .3s;
    background: rgba($scrollbar-color, .75);
  }
}
