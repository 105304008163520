@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../../assets/scss/variables";

.split-pane {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;

  .pane {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &:not(.borderless) {
    > div > .pane:not(:last-child) {
      border-right: 1px solid $gray-300;
    }
  }
}
