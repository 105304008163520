@import "~bootstrap/scss/buttons";
@import "src/assets/scss/variables";

.btn-anchor {
  @extend .btn-link;

  border: 0;
  box-shadow: none;
  padding: 0;
  color: unset;
  font-weight: unset !important;
  font-size: unset;
}

button.anchor {
  background: none!important;
  border: none;
  padding: 0!important;
  cursor: pointer;
}

.btn-white {
  background: white;

  &:hover {
    background-color: $gray-100;
    border-color: $gray-100;
  }
}
