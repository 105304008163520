@import "src/assets/scss/variables";

.modal {
  .modal-header {
    padding: .4rem 1rem;
    border-bottom: 1px solid $gray-200;
  }
}

.modal-xl {
  @include media-breakpoint-up(sm) {
    max-width: 90%;
  }
}
